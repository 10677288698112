.fixed-icon {
  position: fixed;
  bottom: 20px;
  font-size: 2.5rem; /* Adjust size as needed */
  z-index: 1000; /* Ensure it stays on top of other elements */
  color: var(--primary-color); /* Adjust color as needed */
}

.left-icon {
  left: 20px; /* Position on the left side */
}

.right-icon {
  right: 20px; /* Position on the right side */
}

.fixed-icon a {
  color: inherit; /* Ensure the color of the link matches the icon */
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--secondary-color); /* Background color */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.fixed-icon a:hover {
  transform: scale(1.1); /* Slightly enlarge icon on hover */
  background-color: var(--primary-color); /* Change background on hover */
  color: var(--secondary-color); /* Change icon color on hover */
}
