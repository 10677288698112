/* ContactUs.css */
.contact-us-section {
  padding: 60px 20px;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.contact-us-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.contact-form {
  flex: 1;
  padding-right: 20px;
}

.contact-us-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: var(--secondary-color);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 1.2rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: var(--white-color);
  color: var(--secondary-color);
}

.contact-image {
  flex: 1;
  text-align: center;
}

.contact-image img {
  max-width: 100%;
  border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .contact-us-content {
    flex-direction: column;
    align-items: stretch; /* Ensure full-width form */
  }

  .contact-form {
    padding-right: 0;
    width: 100%; /* Make the form take the full width */
  }

  .contact-image {
    display: none; /* Hide the image on screens 1000px wide or smaller */
  }
}

@media (max-width: 768px) {
  .contact-form h2 {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }
}
