/* Testimonials.css */
.testimonials-section {
  padding: 60px 20px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: var(--primary-color);
}

.testimonials-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

}


.testimonial-box {
  flex: 1 1 30%;
  max-width: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--primary-color);
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  color: var(--white-color);

}

.testimonial-photo {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-bottom: 15px;
}

.testimonial-box h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.testimonial-role {
  font-size: 1rem;
  margin-bottom: 15px;
  font-style: italic;
  color: var(--secondary-color);
 

}
.testimonial-box .para {
height: 100px;
 

}

.testimonial-rating {
  font-size: 1.2rem;
  color: var(--secondary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonial-box {
    flex: 1 1 100%;
  }
}
