.footer {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.footer-column {
  flex: 1 1 25%;
  max-width: 250px;
}

.footer-logo {
  width: 150px;
  margin-bottom: 15px;
}

.contact-info p, .social-icons a {
  margin-bottom: 10px;
}

.footer .social-icons a {
  color: var(--white-color);
  font-size: 1.5rem;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.footer .social-icons a:hover {
  color: var(--secondary-color);
}

.footer-column h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: var(--secondary-color);
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: var(--white-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: var(--secondary-color);
}

.newsletter input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.newsletter button {
  width: 100%;
  padding: 10px;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter button:hover {
  background-color: darken(var(--secondary-color), 10%);
  color: var(--white-color);
  border: 2px solid var(--secondary-color);
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--secondary-color);
}
