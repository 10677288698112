/* Services.css */
.services-section {
  padding: 60px 20px;
  background-color: var(--primary-color);
  color: var(--white-color);
  text-align: center;
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: var(--secondary-color);
}

.services-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-box {
  flex: 1 1 30%;
  max-width: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--primary-color);
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.service-box:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color); /* Text color changes to primary */
}

.service-box .service-icon {
  font-size: 3rem;
  margin-bottom: 15px;
  color: var(--white-color);
  transition: color 0.3s ease;
  border: 2px soli red;
}

.service-box:hover .service-icon {
  color: var(--primary-color); /* Icon color changes to primary */
}

.service-box h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.service-box:hover h3 {
  color: var(--primary-color); /* Heading color changes to primary */
}

.service-box p {
  font-size: 1rem;
  margin-bottom: 20px;
  transition: color 0.3s ease;
  height: 90px;
  
}

.service-box:hover p {
  color: var(--primary-color); /* Paragraph color changes to primary */
}

.service-box  .social-icon  {
  display: flex;
  justify-content: center;
  

  gap: 20px;
  
}

.service-box  .social-icon a {
  font-size: 1.5rem;
  color: var(--white-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.service-box  :hover  .social-icon  {
  color: var(--primary-color); /* Social icons color changes to primary */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .service-box {
    flex: 1 1 100%;
  }
}
