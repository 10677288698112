/* About.css */
.about-section {
  padding: 60px 20px;
  background-color: var(--primary-color); /* Replace with your primary color */
  color: var(--secondary-color); /* Replace with your secondary color */
  text-align: center;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-content h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: var(--secondary-color); /* Replace with your secondary color */
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
 
}
