.portfolio-section {
  padding: 60px 20px;
  background-color: var(--primary-color);
  text-align: center;
}

.portfolio-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: var(--secondary-color);
}

.portfolio-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.portfolio-item {
  position: relative;
  max-width: 300px;
  flex: 1 1 30%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.portfolio-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
  border-radius: 10px;
}

.portfolio-item:hover {
  transform: translateY(-10px);
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio-overlay h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.portfolio-overlay p {
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .portfolio-item {
    flex: 1 1 100%;
  }
}
