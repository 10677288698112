:root {
  --primary-color: #182937;
  --secondary-color: #FCD200;
  --white-color: #FFFFFF;
}

body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, p {
  font-family: 'Roboto', sans-serif;
 color: var(--white-color);
}

.btn{
  font-family: 'Roboto', sans-serif;

}
a {
  text-decoration: none;
  color: black;
}
