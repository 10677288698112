/* NavBar.css */

.NavBar {
  position: fixed; /* Use fixed positioning for a persistent top position */
  top: 0; /* Align to the top of the viewport */
  left: 0;
  right: 0;
  z-index: 999; /* Ensure it appears on top of other elements */
  background-color: var(--primary-color); /* Default background color */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  padding: 10px 20px; /* Add some padding for better spacing */
}

.navbar-brand {
  font-weight: 900;
  font-size: 2rem;
  margin-left: 10px;
  letter-spacing: 1px;
  color: var(--white-color);
  text-transform: uppercase;
}

.navbar-brand:hover {
  color: var(--secondary-color);
}

.nav-link {
  color: var(--white-color);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-left: 15px;
}

.nav-link:hover {
  color: var(--secondary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-brand {
    font-size: 0.9rem;
    margin: 0%;
    padding: 5px;
  }

  .NavBar {
    top: 0; 
  }
}


.NavBar .navbar-toggler {
  border: none;
  color: var(--secondary-color);
  background-color: var(--white-color);
 height: 30px;
  
}


.NavBar .navbar-collapse {
  display: flex;
  align-items: center;
}

.NavBar .navbar-nav {
  margin-left: auto;
}

.NavBar .nav-link {
  color: var(--white-color);
  margin-left: 15px;
  font-size: 1rem;
}

.NavBar .nav-link:hover {
  color: var(--secondary-color);
}

/* Offcanvas styling */
.offcanvas-nav {
  background-color: var(--primary-color);
}

.offcanvas-nav .offcanvas-header {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.offcanvas-nav .offcanvas-title {
  color: var(--secondary-color);
}

.offcanvas-nav .offcanvas-body {
  background-color: var(--primary-color);
}

.offcanvas-nav .nav-link {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.offcanvas-nav .nav-link:hover {
  color: var(--secondary-color);
}

/* Ensure the navbar is positioned properly on large screens */
@media (min-width: 992px) {
  .NavBar .navbar-toggler {
    display: none; /* Hide the toggler button on large screens */
  }

  .NavBar .navbar-collapse {
    display: flex;
    justify-content: flex-start; /* Align nav items to the left */
  }

  .NavBar .navbar-brand {
    margin-right: auto; /* Align the brand name to the left */
  }
}

@media (max-width: 992px) {
  .NavBar .navbar-collapse {
    display: none; /* Hide navbar links on small screens */
  }
 .NavBar .languageChanger{
    display: block;
  }
}

.languageChanger{
  display: none;
}
