/* HeroSection.css */
.hero-section {
  position: relative;
  background-image: url('https://media.istockphoto.com/id/1186577710/photo/close-up-of-unrecognizable-delivery-man-with-cardboard-box.jpg?b=1&s=612x612&w=0&k=20&c=EDDPzclntK8tfshcUy2ayOxQOQUT8FOmsqUvcjsZkCc='); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0 20px;
}





.hero-content {
  width: 100%;
  text-align: center;
}

.type-animation {
  font-size: 3rem; /* Adjust font size as needed */
  font-weight: bold;
  color: white;
  text-transform: uppercase;

}
/* HeroSection.css */
.hero-paragraph {
  margin-top: 20px;
  font-size: 1.5rem; /* Adjust font size as needed */
  color: white; /* Ensure visibility against the background */
  max-width: 600px;
  line-height: 1.6;
}

.hero-section .button{
  margin-top: 10px;
  width: 100%;
}
.hero-section .btn{
  text-transform: capitalize;
  font-weight: 500;
  font-size: 20px;
  width: 200px;
  color: white;
  background-color: var(--primary-color);
}
 .hero-section .btn:hover{
  background-color: var(--secondary-color);
  color: var(--primary-color);
 }


/* Responsive adjustments */
@media (max-width: 768px) {
  .type-animation {
    font-size: 1.8rem;
  }
}
