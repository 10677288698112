.language-switcher {
  display: flex;
}

.language-switcher button {
  border: none;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  width: 100px;
  color: var(--primary-color) !important;
  background-color:var(--secondary-color) !important;
}

.language-switcher button:last-child {
  margin-right: 0;
}

.language-switcher button:hover {
  background-color:var(--primary-color) !important;
  color: var(--secondary-color) !important;
  border:2px solid  var(--secondary-color) !important;
}
.language-switcher .dropdown-item:hover {
  background-color: var(--primary-color);
  color:var(--secondary-color)
  
}
.language-switcher .dropdown-item.active {
  background: var(--primary-color)
 
}
.language-switcher .dropdown-menu {
  background-color: white;
}
.language-switcher #dropdown-basic-button{
  background-color: transparent;

  }
  .language-switcher .dropdown-item{
    font-family: "Hanken Grotesk", sans-serif;
    font-weight:600;
    font-size:14px;
    
  }

  @media (max-width: 992px) {
    .language-switcher button {
      border: none;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 13px;
      width: 70px;
      margin-top: 0px;
      height: 30px !important;
      margin-right: -3px;
     
     
    }
  }




